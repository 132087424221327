
$white: #ffffff;
$primary-color: #EB1F24;
$heading-color: #5A4A42;
$paragraph-color: #777777;


$disable: #a5a5a5;
$error-color: #ef5350;
$white-color: #fff;
$grey-color: #c4c9d1;
$light-grey-color: #e6e8eb;

$fontFamilyBase: 'Roboto', sans-serif;
$fontFamilySecond: 'SF Pro Display';

$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;
