@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import "node_modules/react-modal-video/scss/modal-video.scss";

@font-face {
  font-family: "SF Pro Display";
  src: url("./fonts/SFProDisplay-Medium.woff2") format("woff2"),
    url("./fonts/SFProDisplay-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./fonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("./fonts/SFProDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("./fonts/SFProDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import "./variables";

html {
  line-height: 1;
  -webkit-text-size-adjust: 100%;
  font-size: 14px;
  font-weight: 400;
}

body {
  margin: 0;
  font-size: 1rem;
  color: $paragraph-color;
  background-color: $white;
  font-family: $fontFamilyBase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
}

a:hover {
  color: $primary-color;
}

button {
  font-family: $fontFamilySecond;
  font-weight: 400;
}
.creator {
  background: linear-gradient(
    to top,
    #ffffff,
    #faf6fc,
    #f8ecf6,
    #f9e2ee,
    #fad8e1,
    #fad8e1,
    #fad8e1,
    #fad8e1,
    #f9e2ee,
    #f8ecf6,
    #faf6fc,
    #ffffff
  );
  height: auto;
  width: 100%;
  // margin: -8px;
  // position: absolute;
}

.MuiModal-root {
  overflow: auto;
}

.kiHXWb {
  flex: 1 1 0%;
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  background: rgb(229, 45, 39) !important;
  padding: 20px !important;
  margin: 0px;
  max-width: none !important;
  color: white;
  border-color: rgb(238, 238, 238) !important;
  border-style: dashed !important;
  height: 170px !important;
}

.AVDrag {
  border: none !important;
}

.AVDrag svg {
  display: none;
}

.AVDrag * {
  color: white !important;
  font-size: 14px !important;
}

.sc-bczRLJ span {
  margin-top: 80px;
}

.modal-video-close-btn {
  width: 35px !important;
  height: 35px !important;
  background: transparent !important;
}

a {
  text-decoration: none;
}
